<script>
import {
  defineComponent, ref, onMounted, computed, inject,
} from '@vue/composition-api'
import { useMessage } from '@/hooks/useMessage'
import ChannelLink from '@/views/ways/register/components/channelLink/Link.vue'
import { getDetail } from '../api'
import { exportPdf } from '../toPdf'

export default defineComponent({
  components: {
    ChannelLink,
  },
  setup() {
    const currId = inject('currId')

    const formData = ref({})
    const channels = ref([])
    const isLoading = ref(false)
    const isNewAndReplace = computed(() => [0, 2, 3].includes(formData.value.registerTypeId) && formData.value.operatingModeId === 1)
    const applicationNo = ref()

    // 人工代运营 + 新开/替换/自带 = 复杂模式
    const isComplex = computed(() => (formData.value.operatingModeId === 3 && [0, 2, 3].includes(formData.value.registerTypeId)))

    // 人工代运营 + 合并 || 人机混合代运营/ 机器代运营 + 新开/合并/替换/自带 = 简单模式
    const isCombine = computed(() => (formData.value.operatingModeId === 3 && formData.value.registerTypeId === 1) || [0, 1].includes(formData.value.operatingModeId))

    // 创作者自运营 + 新开/合并/替换/自带 = 极简模式
    //  const isSimple = computed(() => operatingMode.value === 1)

    const { message } = useMessage()

    // const { exportPdf } = htmlToPdf()

    const initData = data => {
      formData.value = {
        ...data,
        audienceAreaList: data.audienceAreaList && data.audienceAreaList?.split(','),
        audienceGroupList: data.audienceGroupList && data.audienceGroupList?.split(','),
        competitiveProductChannel: data.competitiveProductChannel && JSON.parse(data.competitiveProductChannel),
        contentOperatingModeList: data.contentOperatingModeList && data.contentOperatingModeList?.split(','),
        estimatedLevelName: data.estimatedLevel,
        operatingModeName: data.operatingModeName,
        categoryName: data.categoryName && `${data.categoryName}`,
        operationPlatformList: data.operationPlatformList,
        profitCycleName: data.profitCycle,
        registerTypeName: data.registerTypeName,
        releaseFormat: data.releaseFormat && JSON.parse(data.releaseFormat),
        timeOfTimingRelease: data.timeOfTimingRelease,
        operationModeName: data.operationModeName,
        operationManagerEmail: data.operationManagerEmail && JSON.parse(data.operationManagerEmail),
        operationLeaderEmail: data.operationLeaderEmail && JSON.parse(data.operationLeaderEmail),
        operatingMemberEmail: data.operatingMemberEmail && JSON.parse(data.operatingMemberEmail),

      }
      channels.value = data.contentJson ? JSON.parse(data.contentJson) : []
    }

    const fetchDetail = async () => {
      isLoading.value = true
      const { data: { data = {}, status, message: messageText } } = await getDetail({ id: currId })
      if (status === 200) {
        initData(data.opeContentApplicationDetail || {})
        applicationNo.value = data.applicationNo || '-'
      } else {
        message.error(messageText)
      }
      isLoading.value = false
    }

    const resolveOtherText = computed(() => (list, otherkey) => {
      let otherText = ''
      const newList = list || []
      if (newList.includes('其他')) {
        otherText = `其他-${formData.value[otherkey]}`
      }
      const otherList = newList.filter(x => x !== '其他')

      if (otherText) {
        otherList.push(otherText)
      }

      return newList ? otherList.join('、') : '-'
    })

    const handerPdf = () => {
      exportPdf({ width: 210, height: 297 }, document.getElementById('pdfDoms'), `领用申请单${applicationNo.value}`)
    }

    onMounted(() => {
      fetchDetail()
    })

    const isYoutube = computed(() => formData.value.operationPlatformList === 'YouTube')

    const getEmailText = (arr = []) => arr.join('、')

    return {
      formData,
      channels,
      isLoading,
      isNewAndReplace,
      resolveOtherText,
      handerPdf,
      applicationNo,
      isComplex,
      isCombine,
      isYoutube,
      getEmailText,
    }
  },
})
</script>

<template>
  <div class="wrapper">
    <div class="exportPdf" @click="handerPdf">
      导出PDF
    </div>
    <v-form
      v-if="!isLoading"
      id="pdfDoms"
      ref="formRef"
      class="form"
    >
      <div class="form-number">
        <span>{{ applicationNo }}</span>
      </div>

      <div class="form-inner">
        <div class="form-title">
          运营平台
        </div>
        <table class="table-box">
          <tr>
            <td class="table-title">
              运营平台
            </td>
            <td class="table-content">
              {{ formData.operationPlatformList || '-' }}
            </td>
          </tr>
        </table>
      </div>
      <div class="form-inner">
        <div class="form-title">
          运营团队/成员
        </div>
        <table class="table-box">
          <tr>
            <td class="table-title">
              运营团队
            </td>
            <td class="table-content">
              {{ formData.operationTeam }}
            </td>
          </tr>
          <tr>
            <td class="table-title">
              运营组别
            </td>
            <td class="table-content">
              {{ formData.operationGroupName }}
            </td>
          </tr>
          <tr>
            <td class="table-title">
              运营经理
            </td>
            <td class="table-content">
              {{ formData.operationManagerName || '-' }}
              <span v-if="isYoutube" class="name-email">{{ getEmailText(formData.operationManagerEmail) }}</span>
              <div v-for="email,index in formData.operationManagerEmail" v-else :key="index" class="name-email ml-0">
                {{ email || '-' }}
              </div>
            </td>
          </tr>
          <tr>
            <td class="table-title">
              运营组长
            </td>
            <td class="table-content">
              {{ formData.operationLeaderName || '-' }}
              <span v-if="isYoutube" class="name-email">{{ getEmailText(formData.operationLeaderEmail) }}</span>
              <div v-for="email,index in formData.operationLeaderEmail" v-else :key="index" class="name-email ml-0">
                {{ email || '-' }}
              </div>
            </td>
          </tr>
          <tr>
            <td class="table-title">
              运营组员
            </td>
            <td class="table-content">
              {{ formData.operatingMemberName || '-' }}
              <span v-if="isYoutube" class="name-email">{{ getEmailText(formData.operatingMemberEmail) }}</span>
              <div v-for="email,index in formData.operatingMemberEmail" v-else :key="index" class="name-email ml-0">
                {{ email || '-' }}
              </div>
            </td>
          </tr>
        </table>
      </div>

      <div class="form-inner">
        <div class="form-title">
          签约频道/作品
        </div>
        <div class="form-channels">
          <table class="table-box">
            <tr>
              <th>频道/作品名称</th><th>CP名称</th><th>合同生效日期</th>
              <th>跟进人姓名</th>
              <th>跟进人角色</th>
              <th>跟进人组别</th>
              <!-- <th>创作者需求</th> -->
            </tr>
            <tr
              v-for="row in channels"
              :key="row.id"
            >
              <td>
                <div class="channel-inner">
                  <ChannelLink
                    :channel="{
                      platform: row.platform,
                      channelName: row.channelName,
                      link: row.link
                    }"
                    :type="{
                      subChannel: row.subChannel,
                      selfChannel: row.selfChannel
                    }"
                    :self-channel="{
                      platform: row.selfPlatform,
                      channelName: row.selfChannelName,
                      link: row.selfChannelLink
                    }"
                  />
                  <span
                    v-if="row.isMainChannel && channels.length > 1"
                    class="channel-main"
                  >主频道</span>
                </div>
              </td>
              <td>{{ row.copyrightHolder || '-' }}</td><td>{{ row.startDate }}~{{ row.endDate }}</td>
              <td>{{ row.followUserName || '-' }}</td>
              <td>{{ row.followUserRole || '-' }}</td>
              <td>{{ row.followUserDept || '-' }}</td>
              <!-- <td>{{ row.creatorDemand || '-' }}</td> -->
            </tr>
            <tr v-if="!channels.length">
              <td
                :colspan="4"
                align="center"
                style="font-size: 14px;color: rgba(0,0,0,0.4);"
              >
                暂无数据
              </td>
            </tr>
          </table>
        </div>
      </div>

      <div
        v-if="isComplex"
        class="form-inner"
      >
        <div class="form-title">
          运营方案
        </div>
        <table class="table-box">
          <tr>
            <td class="table-title">
              频道/主页申请类型
            </td>
            <td class="table-content">
              {{ formData.registerTypeName }}
            </td>
            <td class="table-title">
              频道/主页名称
            </td>
            <td class="table-content">
              <ChannelLink
                v-if="formData.registerChannelName"
                :channel="{
                  platform: formData.operationPlatformList,
                  channelName: formData.registerChannelName,
                }"
              />
              <span v-else>-</span>
            </td>
          </tr>
          <tr>
            <td class="table-title">
              频道/主页ID
            </td>
            <td class="table-content">
              {{ formData.registerChannelId || '-' }}
            </td>
            <td class="table-title">
              主要受众群体
            </td>
            <td class="table-content">
              {{ formData.audienceGroupList ? formData.audienceGroupList.join('、') : '-' }}
            </td>
          </tr>

          <tr v-if="formData.registerTypeId ===2">
            <td class="table-title">
              是否使用原频道/主页
            </td>
            <td class="table-content">
              {{ formData.registerChannelName ? '是' : '否' }}
            </td>
            <td class="table-title">
              新频道/主页名
            </td>
            <td class="table-content">
              {{ formData.registerChannelName || '-' }}
            </td>
          </tr>
          <tr>
            <td class="table-title">
              运营模式
            </td>
            <td class="table-content">
              {{ formData.operatingModeName || '-' }}
            </td>
            <td class="table-title">
              受众地区
            </td>
            <td class="table-content">
              {{ resolveOtherText(formData.audienceAreaList, 'audienceAreaOther') }}
            </td>
          </tr>
          <tr>
            <td class="table-title">
              垂类
            </td>
            <td class="table-content">
              {{ formData.categoryName || '-' }}
            </td>
            <td class="table-title">
              观众性别
            </td>
            <td class="table-content">
              男性：{{ formData.audienceSexMan }}，女性：{{ formData.audienceSexWoman }}
            </td>
          </tr>
          <tr>
            <td class="table-title">
              运营类型
            </td>
            <td class="table-content">
              {{ formData.operationModeName || '-' }}
            </td>
            <td class="table-title">
              内容运营模式
            </td>
            <td class="table-content">
              {{ resolveOtherText(formData.contentOperatingModeList, 'contentOperatingModeOther') }}
            </td>
          </tr>
          <tr v-if="formData.selfChannelLink">
            <td class="table-title">
              频道地址
            </td>
            <td class="table-content" colspan="3">
              {{ formData.selfChannelLink }}
            </td>
          </tr>
        </table>
      </div>
      <div
        v-if="!isComplex"
        class="form-inner"
      >
        <div class="form-title">
          运营方案
        </div>
        <table class="table-box">
          <tr>
            <td class="table-title">
              频道/主页申请类型
            </td>
            <td class="table-content">
              {{ formData.registerTypeName || '-' }}
            </td>
          </tr>

          <tr>
            <td class="table-title">
              频道/主页名
            </td>
            <td class="table-content">
              <!-- {{ formData.operatingModeName || '-' }} -->
              <ChannelLink
                v-if="formData.registerChannelName"
                :channel="{
                  platform: formData.operationPlatformList,
                  channelName: formData.registerChannelName,
                }"
              />
              <span v-else>-</span>
            </td>
          </tr>
          <tr>
            <td class="table-title">
              频道/主页ID
            </td>
            <td class="table-content">
              <!-- {{ formData.operatingModeName || '-' }} -->
              {{ formData.registerChannelId || '-' }}
            </td>
          </tr>

          <tr>
            <td class="table-title">
              运营模式
            </td>
            <td class="table-content">
              {{ formData.operatingModeName || '-' }}
            </td>
          </tr>
          <tr>
            <td class="table-title">
              垂类
            </td>
            <td class="table-content">
              {{ formData.categoryName || '-' }}
            </td>
          </tr>
          <tr>
            <td class="table-title">
              运营类型
            </td>
            <td class="table-content">
              {{ formData.operationModeName || '-' }}
            </td>
          </tr>
          <tr v-if="formData.selfChannelLink">
            <td class="table-title">
              频道地址
            </td>
            <td class="table-content">
              {{ formData.selfChannelLink }}
            </td>
          </tr>
        </table>
      </div>
      <div
        v-if="isComplex || isCombine"
        class="form-inner"
      >
        <div class="form-title">
          发布策略
        </div>
        <table class="table-box">
          <tr>
            <td class="table-title">
              发布语言
            </td>
            <td class="table-content">
              {{ formData.releaseLanguage || '-' }}
            </td>
          </tr>
          <tr v-if="!isCombine">
            <td class="table-title">
              封面/标题运营思路
            </td>
            <td class="table-content">
              {{ formData.operatingIdea || '-' }}
            </td>
          </tr>
          <tr>
            <td class="table-title">
              竞品频道
            </td>
            <td class="table-content">
              <div
                v-for="item, index in formData.competitiveProductChannel"
                :key="index"
              >
                {{ item.channelName }}:&nbsp;&nbsp;{{ item.channelLink }}
              </div>
              <div v-if="!formData.competitiveProductChannel || !formData.competitiveProductChannel.length">
                -
              </div>
            </td>
          </tr>
          <tr v-if="!isCombine">
            <td class="table-title">
              参考竞品频道方向
            </td>
            <td class="table-content">
              {{ formData.competitiveChannelDirection || '-' }}
            </td>
          </tr>
          <tr>
            <td class="table-title">
              发布格式
            </td>
            <td class="table-content">
              <div
                v-for="item, index in (formData.releaseFormat || []).filter(item => item.checked)"
                :key="index"
              >
                {{ item.name }}<span v-if="item.duration">({{ item.duration }})min</span>：{{ item.day }} 天 {{ item.times }} 次
              </div>
              <div v-if="!formData.releaseFormat || formData.releaseFormat.length === 0">
                -
              </div>
            </td>
          </tr>
          <tr>
            <td class="table-title">
              定时发布时间
            </td>
            <td class="table-content">
              {{ formData.timeOfTimingRelease || '-' }}
            </td>
          </tr>
        </table>
      </div>
      <div
        v-if="isComplex"
        class="form-inner"
      >
        <div class="form-title">
          目标与评估
        </div>
        <table class="table-box">
          <tr>
            <td class="table-title">
              获利周期
            </td>
            <td class="table-content">
              {{ formData.profitCycleName || '-' }}
            </td>
          </tr>
          <tr>
            <td class="table-title">
              预估获利级别<v-tooltip top>
                <template #activator="{ on, attrs }">
                  <span
                    v-bind="attrs"
                    class="iconfont icon-a-tishishuoming2x2 ml-1"
                    v-on="on"
                  ></span>
                </template>
                <span>判断趋于数据稳定的平均级别</span>
              </v-tooltip>
            </td>
            <td class="table-content">
              {{ formData.estimatedLevelName || '-' }}
            </td>
          </tr>
          <tr>
            <td class="table-title">
              盈利目标 (获利后1年内)
            </td>
            <td class="table-content">
              {{ formData.earningsTargetPerYear || '-' }} 美元
            </td>
          </tr>
          <tr>
            <td class="table-title">
              备注
            </td>
            <td class="table-content">
              {{ formData.remark || '-' }}
            </td>
          </tr>
        </table>
      </div>
    </v-form>
  </div>
</template>
<style lang="scss" scoped>
.wrapper {
  height: 100%;
  position: relative;
  .exportPdf{
    position: absolute;
    right: 20px;
    top: 25px;
    cursor: pointer;
    width: 79px;
    height: 32px;
    line-height: 32px;
    border-radius: 4px;
    border: 1px solid #9155FD;
    font-family: PingFang-SC, PingFang-SC;
    font-weight: 500;
    font-size: 14px;
    color: #9155FD;
    text-align: center;
  }
}
.form {
  padding: 20px 20px 0 20px;
  height: calc(100% - 70px);
  &-inner{
    padding-bottom: 40px;
  }
  &-title{
    font-size: 16px;
    color: rgba(0,0,0,0.85);
    font-weight: bold;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  &-title::before{
    content: '';
    display: block;
    width: 4px;
    height: 16px;
    margin-right: 8px;
    background: #9155FD;
  }
  &-item{
    display: flex;
    align-items: center;
    padding: 10px 0;
    font-size: 14px;
    label{
      width: 160px;
      text-align: right;
      line-height: 20px;
      margin-right: 10px;
      display: flex;
      align-items: center;
      color: rgba(0, 0, 0, 0.85);
    }
  }
  &-content{
    display: flex;
    width: 250px;
    white-space: pre-line;
  }
  &-channels{
    .table-box{
      border-collapse: collapse;
      border: none;
      width: 100%;
      th{
        height: 48px;
        padding: 12px 16px;
        background-color: rgba(247, 249, 251, 1);
        border: 1px solid #ddd;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        text-align: left;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.85);
      }
      td{
        border: 1px solid #ddd;
        padding: 9px 20px;
        width: 240px;
        font-size: 14px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: rgba(0, 0, 0, 0.85);
        ::v-deep .link{
          width: 178px;
        }
      }
      .table-list{
        display: flex;
        flex-wrap: wrap;
        padding: 0;
        width: 658px;
        .table-item{
          padding: 9px 20px;
          border-right: 1px solid #ddd;
          border-bottom: 1px solid #ddd;
        }
      }
    }
    .channel{
      &-box{
        padding: 12px 20px;
        background: #F7F9FB;
        border: 1px solid #E7ECF0;
        border-radius: 6px;
        margin-bottom: 40px;
        position: relative;
        font-size: 14px;
      }
      &-inner{
        display: flex;
        align-items: center;
      }
      &-main{
        color: #FB9716;
        margin-left: 4px;
        background: #FFFAF4;
        border-radius: 3px;
        font-size: 12px;
        line-height: 18px;
        height: 18px;
        padding: 0 4px;
      }
      &-title{
        font-weight: bold;
        color: rgba(0,0,0,0.85);
        margin-bottom: 12px;
      }
      &-list{
        display: flex;
        flex-wrap: wrap;
      }
      &-item{
        margin: 4px 16px 0 0;
        padding: 4px 8px;
        background-color: #fff;
        border-radius: 4px;
        display: flex;
        flex-direction: row;
        align-items: center;
        width: fit-content;
        font-size: 14px;
      }
      &-add{
        position: absolute;
        right: 18px;
        top: 13px;
        cursor: pointer;
        color: var(--v-primary-base);
      }
    }
    ::v-deep .v-input--selection-controls{
      margin-top: 0;
    }
  }
}
.form-number{
  border-bottom: 1px solid #E8E9EC;
  margin-bottom: 40px;
  span{
    display: inline-block;
    width: 167px;
    height: 46px;
    line-height: 46px;
    background: #FFFFFF;
    border-radius: 6px 6px 0px 0px;
    text-align: center;
    border: 1px solid #E8E9EC;
    border-bottom: 0px;
    font-family: PingFang-SC, PingFang-SC;
    font-weight: bold;
    font-size: 16px;
    color: #9155FD;
  }
}
.form-inner{
  padding-left: 34px;
  padding-right: 20px;
}
.form-inner .table-box{
    border-collapse: collapse;
    border: none;
    width: calc(100% - 12px);
    font-size: 14px;
    margin-left: 12px;
    .table-title{
      width: 180px;
      height: 48px;
      padding: 12px 16px;
      background-color: rgba(247, 249, 251, 1);
      border: 1px solid #ddd;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      text-align: left;
      font-size: 14px;
      color: rgba(0,0,0,0.5);
    }
    .table-content{
      border: 1px solid #ddd;
      padding: 9px 20px;
      color: rgba(0,0,0,0.85);
      white-space: pre-line;
      ::v-deep .link{
        width: 178px;
      }
      .name-email{
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: rgba(0,0,0,0.6);
        margin-left: 10px;
      }
    }
  }
</style>
